import React from "react";
import fosLogo from "../images/FOS_Logo_withBG.png";


const MainSection = () => {
  return (
    <section className="landing" id="landing-page">
      <div className="landing-animation">
        <div className="animations">
          <div className="layers-wrapper">
            <div className="layer" id="layer-1"></div>
            <div className="layer" id="layer-2"></div>
            <div className="layer" id="layer-3"></div>
            <div className="layer" id="layer-4"></div>
            <div className="layer" id="layer-5"></div>
            <div className="layer" id="layer-6"></div>
          </div>
        </div>
      </div>
      <div className="landing-text">
        <div className="d-flex">
          <img
            src={fosLogo}
            className="img-fluid mx-1 brain-logo"
            alt="Brain Logo" />
        </div>
        <p>Solutions for the Future</p>
        {/* <button
          className="btn-primary"
          onClick={() => {
            document.getElementById("name").focus();
          }}
        >
          Schedule a Demo
        </button> */}
      </div>
    </section>
  );
};

export default MainSection;
