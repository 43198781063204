/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { paths } from "../constants/paths";
import { Link } from "react-router-dom";
import VideoLinkHeader from "../images/video-link-header.png";
import fosLogo from "../images/FOS_Logo_withBG.png";

const Header = (props) => {
  useEffect(() => {
    const html = document.querySelector("html");
    html.addEventListener("click", (event) => {
      if (window.matchMedia("screen and (max-width: 768px)").matches) {
        const navBar = document.querySelector(".navbar-collapse");
        const isMobileMenuOpen = navBar.classList.contains("show");
        if (isMobileMenuOpen && event.target.id !== "menu-button") {
          closeMenu();
        }
      }
    });
  });

  const getActivePath = (location) => {
    let activePath = "";
    Object.keys(paths).forEach((item) => {
      if (paths[item] === location) {
        activePath = paths[item];
      }
    });
    return activePath;
  };

  const closeMenu = () => {
    if (window.matchMedia("screen and (max-width: 768px)").matches) {
      const menuIcon = document.querySelector("button.navbar-toggler");
      if (menuIcon) {
        menuIcon.click();
      }
    }
  };

  const location = useLocation();
  let activePath = getActivePath(location.pathname);

  return (
    <header className="navbar-dark bg-navbar">
      <nav className="container navbar navbar-expand-md">
        <Link
          to={paths.home}
          className={`nav-link navbar-brand d-flex ${
            activePath === paths.home ? "" : ""
          }`}
        >
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={fosLogo}
              className="img-fluid brain-logo"
              alt="Brain Logo"
            />
          </div>
          <span className="f-30">&reg;</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          id="menu-button"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav ml-auto">
            <li
              className={`nav-item ${
                activePath === paths.aboutUs ? "active" : ""
              }`}
            >
              <Link to={paths.aboutUs} className="nav-link">
                About Us
              </Link>
            </li>
            <li
              className={`nav-item ${
                activePath === paths.ourLeadership ? "active" : ""
              }`}
            >
              <Link to={paths.ourLeadership} className="nav-link">
                Our Leadership
              </Link>
            </li>

            <li
              className={`nav-item ${
                activePath === paths.whyFinoptsys ? "active" : ""
              }`}
            >
              <Link to={paths.whyFinoptsys} className="nav-link">
                Why FinOptSys
              </Link>
            </li>

            <li
              className={`nav-item ${
                activePath === paths.businessConcept ? "active" : ""
              }`}
            >
              <Link to={paths.businessConcept} className="nav-link">
                Business
              </Link>
            </li>
            <li
              className={`nav-item ${
                activePath === paths.contactUs ? "active" : ""
              }`}
            >
              <Link to={paths.contactUs} className="nav-link">
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={paths.aboutUs}
                className="nav-link p-0 mb-0 m-auto ml-sm-3"
              >
                <img src={VideoLinkHeader} alt="video" className="video-icon" />
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
