import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../constants/paths";

const TermsAndConditions = (props) => {
  const moveToElement = (id) => {
    const anchor = document.querySelector(id);
    anchor.scrollIntoView({ block: "center" });
  };
  return (
    <main role="main" className="d-flex flex-column terms-privacy-main">
      <section class="dark-bg" id="terms">
        <div className="container pt-5 mt-5 mt-md- px-lg-0">
          <div className="terms-privacy-body-header">
            <h1>Terms of Use</h1>
            <h6 className="mb-24">Effective date: October 2024</h6>
          </div>
          <div class="terms-content row">
            <div class="col-16">
              <p>
                Welcome to FinOptSys. Please read on to learn the rules and
                restrictions that govern your use of our website(s) (the
                “Services”). If you have any questions, comments, or concerns
                regarding these terms or the Services, please contact us at:
                info@finoptsys.com
              </p>
              <p>
                These Terms of Use (the “Terms”) are a binding contract between
                you and{" "}
                <span className="font-weight-bold text-capatilize px-1">
                  FINOPTSYS, INC.
                </span>{" "}
                (“FinOptSys,” “we” and “us”). Your use of the Services in any
                way means that you agree to all of these Terms, and these Terms
                will remain in effect while you use the Services. These Terms
                include the provisions in this document as well as those in the
                &nbsp;<Link to={paths.privacyPolicy}>Privacy Policy</Link>.
                <span className="font-weight-bold px-1">
                  {" "}
                  Your use of or participation in certain Services may also be
                  subject to additional policies, rules and/or conditions
                  (“Additional Terms”), which are incorporated herein by
                  reference, and you understand and agree that by using or
                  participating in any such Services, you agree to also comply
                  with these Additional Terms.{" "}
                </span>
              </p>
              <p>
                <span className="font-weight-bold px-1">
                  Please read these Terms carefully.{" "}
                </span>
                They cover important information about Services provided to you
                and any charges, taxes, and fees we bill you.
                <span className="font-weight-bold px-1">
                  These Terms include information about &nbsp;
                  <span
                    className="link-color cursor-pointer"
                    onClick={() => moveToElement("#futureChanges")}
                  >
                    future changes
                  </span>
                  &nbsp; to these Terms,&nbsp;
                  <span
                    className="link-color cursor-pointer"
                    onClick={() => moveToElement("#limitationLiability")}
                  >
                    limitations of liability
                  </span>
                  &nbsp;, a class action waiver and resolution of &nbsp;
                  <span
                    className="link-color cursor-pointer"
                    onClick={() => moveToElement("#disputesArbitration")}
                  >
                    disputes by arbitration
                  </span>
                  &nbsp;instead of in court. PLEASE NOTE THAT YOUR USE OF AND
                  ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF
                  YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR
                  ACCESS THE SERVICES IN ANY MANNER.
                </span>
              </p>
              <p className="mb-36">
                <span className="font-weight-bold px-1">
                  ARBITRATION NOTICE AND CLASS ACTION WAIVER:
                </span>
                EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE &nbsp;
                <span
                  className="link-color cursor-pointer"
                  onClick={() => moveToElement("#disputesArbitration")}
                >
                  ARBITRATION AGREEMENT
                </span>
                &nbsp;SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US
                WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU
                WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR
                CLASS-WIDE ARBITRATION.
              </p>
              <h5 className="" id="futureChanges">
                Will these Terms ever change?
              </h5>
              <p>
                We are constantly trying to improve our Services, so these Terms
                may need to change along with our Services. We reserve the right
                to change the Terms at any time, but if we do, we will place a
                notice on our site located at
                <a
                  href="https://www.finoptsys.com/"
                  rel="noopener noreferrer"
                  className="pl-1"
                >
                  www.finoptsys.com
                </a>
                , send you an email, and/or notify you by some other means.
              </p>
              <p>
                If you don’t agree with the new Terms, you are free to reject
                them; unfortunately, that means you will no longer be able to
                use the Services. If you use the Services in any way after a
                change to the Terms is effective, that means you agree to all of
                the changes.{" "}
              </p>
              <p className="mb-36">
                Except for changes by us as described here, no other amendment
                or modification of these Terms will be effective unless in
                writing and signed by both you and us.
              </p>

              <h5 className="">What about my privacy?</h5>
              <p>
                FinOptSys takes the privacy of its users very seriously. For the
                current FinOptSys Privacy Policy, please click{" "}
                <Link to="/privacy-policy" className="terms-link">
                  here
                </Link>
                .
              </p>
              <h5>What are the basics of using FinOptSys?</h5>
              <p>
                You may be required to provide us with certain information or
                data, such as your contact information. You promise to provide
                us with accurate, complete, and updated information about
                yourself.
              </p>
              <p>
                If you’re agreeing to these Terms on behalf of an organization
                or entity, you represent and warrant that you are authorized to
                agree to these Terms on the organization’s or entity’s behalf
                and bind them to these Terms (in which case, the references to
                “you” and “your” in these Terms, except for in this sentence,
                refer to that organization or entity).
              </p>
              <p className="mb-36">
                You will only use the Services for your own use, and not on
                behalf of or for the benefit of any third party. You will comply
                with all laws that apply to you, your use of the Services, and
                your actions and omissions that relate to the Services. If your
                use of the Services is prohibited by applicable laws, then you
                aren’t authorized to use the Services. We can’t and won’t be
                responsible for your using the Services in a way that breaks the
                law.
              </p>
              <h5>Will FinOptSys Message me?</h5>
              <p className="mb-36">
                As part of the Services, you may receive communications through
                the Services, including messages that FinOptSys sends you (for
                example, via email or SMS). When signing up for the Services,
                you may receive a welcome message and instructions on how to
                stop receiving the messages.
                <span className="font-weight-bold px-1">
                  By signing up for the Services and providing us with your
                  wireless number, you confirm that you want FinOptSys to send
                  you information regarding your account or transactions with
                  us, which may include FinOptSys using automated dialing
                  technology to text you at the wireless number you provided,
                  and you agree to receive communications from FinOptSys, and
                  you represent and warrant that each person you register for
                  the Services or for whom you provide a wireless phone number
                  has consented to receive communications from FinOptSys.
                </span>
                You agree to indemnify and hold FinOptSys harmless from and
                against any and all claims, liabilities, damages (actual or
                consequential), losses and expenses (including attorneys’ fees)
                arising from or in any way related to your breach of the
                foregoing.
              </p>
              <h5>Are there restrictions in how I can use the Services?</h5>
              <p>
                ou represent, warrant, and agree that you will not contribute
                any Content (as defined below) or otherwise use the Services or
                interact with the Services in a manner that:
              </p>
              <ul className="list-unstyled p-0 mb-24">
                <li>
                  (a) infringes or violates the intellectual property rights or
                  any other rights of anyone else (including FinOptSys);
                </li>
                <li>
                  (b) violates any law or regulation, including, without
                  limitation, any applicable export control laws, privacy laws
                  or any other purpose not reasonably intended by FinOptSys;
                </li>
                <li>
                  (c) is dangerous, harmful, fraudulent, deceptive, threatening,
                  harassing, defamatory, obscene, or otherwise objectionable;
                </li>
                <li>
                  (d) attempts, in any manner, to obtain the password, account,
                  or other security information from any other user;
                </li>
                <li>
                  (e) violates the security of any computer network, or cracks
                  any passwords or security encryption codes;
                </li>
                <li>
                  (f) runs Maillist, Listserv, any form of auto-responder or
                  “spam” on the Services, or any processes that run or are
                  activated while you are not logged into the Services, or that
                  otherwise interfere with the proper working of the Services
                  (including by placing an unreasonable load on the Services’
                  infrastructure);
                </li>
                <li>
                  (g) “crawls,” “scrapes,” or “spiders” any page, data, or
                  portion of or relating to the Services or Content (through use
                  of manual or automated means);
                </li>
                <li>
                  (h) copies or stores any significant portion of the Content;
                  or
                </li>
                <li>
                  (i) decompiles, reverse engineers, or otherwise attempts to
                  obtain the source code or underlying ideas or information of
                  or relating to the Services.
                </li>
              </ul>
              <p className="mb-36">
                A violation of any of the foregoing is grounds for termination
                of your right to use or access the Services.{" "}
              </p>
              <h5>What are my rights in the Services?</h5>
              <p className="mb-0">
                The materials displayed or performed or available on or through
                the Services, including, but not limited to, text, graphics,
                data, articles, photos, images, illustrations and so forth (all
                of the foregoing, the “Content”) are protected by copyright
                and/or other intellectual property laws. You promise to abide by
                all copyright notices, trademark rules, information, and
                restrictions contained in any Content you access through the
                Services, and you won’t use, copy, reproduce, modify, translate,
                publish, broadcast, transmit, distribute, perform, upload,
                display, license, sell, commercialize or otherwise exploit for
                any purpose any Content not owned by you, (i) without the prior
                consent of the owner of that Content or (ii) in a way that
                violates someone else’s (including FinOptSys’) rights.
              </p>
              <p className="mb-36">
                Subject to these Terms, we grant each user of the Services a
                worldwide, non-exclusive, non-sublicensable and non-transferable
                license to use (i.e., to download and display locally) Content
                solely for purposes of using the Services. Use, reproduction,
                modification, distribution or storage of any Content for any
                purpose other than using the Services is expressly prohibited
                without prior written permission from us. You understand that
                FinOptSys owns the Services. You won’t modify, publish,
                transmit, participate in the transfer or sale of, reproduce
                (except as expressly provided in this Section), create
                derivative works based on, or otherwise exploit any of the
                Services. The Services may allow you to copy or download certain
                Content, but please remember that even where these
                functionalities exist, all the restrictions in this section
                still apply.
              </p>
              <h5>Who is responsible for what I see and do on the Services?</h5>
              <p>
                Any information or Content publicly posted or privately
                transmitted through the Services is the sole responsibility of
                the person from whom such Content originated, and you access all
                such information and Content at your own risk, and we aren’t
                liable for any errors or omissions in that information or
                Content or for any damages or loss you might suffer in
                connection with it. We cannot control and have no duty to take
                any action regarding how you may interpret and use the Content
                or what actions you may take as a result of having been exposed
                to the Content, and you hereby release us from all liability for
                you having acquired or not acquired Content through the
                Services. We can’t guarantee the identity of any users with whom
                you interact in using the Services and are not responsible for
                which users gain access to the Services.{" "}
              </p>
              <p>
                You are responsible for all Content you contribute, in any
                manner, to the Services, and you represent and warrant you have
                all rights necessary to do so, in the manner in which you
                contribute it.
              </p>
              <p className="mb-36">
                The Services may contain links or connections to third-party
                websites or services that are not owned or controlled by
                FinOptSys. When you access third-party websites or use
                third-party services, you accept that there are risks in doing
                so, and that FinOptSys is not responsible for such risks.{" "}
              </p>
              <p>
                FinOptSys has no control over, and assumes no responsibility
                for, the content, accuracy, privacy policies, or practices of or
                opinions expressed in any third-party websites or by any third
                party that you interact with through the Services. In addition,
                FinOptSys will not and cannot monitor, verify, censor or edit
                the content of any third-party site or service. We encourage you
                to be aware when you leave the Services and to read the terms
                and conditions and privacy policy of each third-party website or
                service that you visit or utilize. By using the Services, you
                release and hold us harmless from any and all liability arising
                from your use of any third-party website or service.
              </p>
              <p>
                Your interactions with organizations and/or individuals found on
                or through the Services, and any other terms, conditions,
                warranties or representations associated with such dealings, are
                solely between you and such organizations and/or individuals.
                You should make whatever investigation you feel necessary or
                appropriate before proceeding with any online or offline
                transaction with any of these third parties. You agree that
                FinOptSys shall not be responsible or liable for any loss or
                damage of any sort incurred as the result of any such dealings.
              </p>
              <p className="mb-36">
                If there is a dispute between participants on this site or
                Services, or between users and any third party, you agree that
                FinOptSys is under no obligation to become involved. In the
                event that you have a dispute with one or more other users, you
                release FinOptSys, its directors, officers, employees, agents,
                and successors from claims, demands, and damages of every kind
                or nature, known or unknown, suspected or unsuspected, disclosed
                or undisclosed, arising out of or in any way related to such
                disputes and/or our Services. You shall and hereby do waive
                California Civil Code Section 1542 or any similar law of any
                jurisdiction, which says in substance: “A general release does
                not extend to claims that the creditor or releasing party does
                not know or suspect to exist in his or her favor at the time of
                executing the release and that, if known by him or her, would
                have materially affected his or her settlement with the debtor
                or released party.”
              </p>
              <h5>Will FinOptSys ever change the Services?</h5>
              <p className="mb-36">
                We’re always trying to improve our Services, so they may change
                over time. We may suspend or discontinue any part of the
                Services, or we may introduce new features or impose limits on
                certain features or restrict access to parts or all of the
                Services. We reserve the right to remove any Content from the
                Services at any time, for any reason (including, but not limited
                to, if someone alleges you contributed that Content in violation
                of these Terms), in our sole discretion, and without notice.
              </p>
              <h5>What if I want to stop using the Services?</h5>
              <p>
                You’re free to do that at any time; please refer to our &nbsp;
                <Link className="pr-1" to="/privacy-policy">
                  Privacy Policy
                </Link>
                , as well as the licenses above, to understand how we treat
                information you provide to us after you have stopped using our
                Services.{" "}
              </p>
              <p>
                FinOptSys is also free to terminate (or suspend access to) your
                use of the Services for any reason in our discretion, including
                your breach of these Terms. FinOptSys has the sole right to
                decide whether you are in violation of any of the restrictions
                set forth in these Terms.
              </p>
              <p className="mb-36">
                Provisions that, by their nature, should survive termination of
                these Terms shall survive termination. By way of example, all of
                the following will survive termination: any obligation you have
                to pay us or indemnify us, any limitations on our liability, any
                terms regarding ownership or intellectual property rights, and
                terms regarding disputes between us, including without
                limitation the arbitration agreement.
              </p>
              <h5>What else do I need to know?</h5>
              <p>
                Warranty Disclaimer. FinOptSys and its licensors, suppliers,
                partners, parent, subsidiaries or affiliated entities, and each
                of their respective officers, directors, members, employees,
                consultants, contract employees, representatives and agents, and
                each of their respective successors and assigns (FinOptSys and
                all such parties together, the “FinOptSys Parties”) make no
                representations or warranties concerning the Services, including
                without limitation regarding any Content contained in or
                accessed through the Services, and the FinOptSys Parties will
                not be responsible or liable for the accuracy, copyright
                compliance, legality, or decency of material contained in or
                accessed through the Services or any claims, actions, suits
                procedures, costs, expenses, damages or liabilities arising out
                of use of, or in any way related to your participation in, the
                Services. The FinOptSys Parties make no representations or
                warranties regarding suggestions or recommendations of services
                or products offered or purchased through or in connection with
                the Services. THE SERVICES AND CONTENT ARE PROVIDED BY FINOPTSYS
                (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT
                WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
                WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE
                OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES
                DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS,
                SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.{" "}
              </p>
              <p id="limitationLiability">
                Limitation of Liability. TO THE FULLEST EXTENT ALLOWED BY
                APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY
                (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT
                LIABILITY, OR OTHERWISE) SHALL ANY OF THE FINOPTSYS PARTIES BE
                LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT,
                SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY
                KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION,
                LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF
                RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE
                GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE,
                IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100) DOLLARS OR
                (II) THE AMOUNTS PAID AND/OR PAYABLE BY YOU TO FINOPTSYS IN
                CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD
                PRECEDING THIS APPLICABLE CLAIM OR (D) ANY MATTER BEYOND OUR
                REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR
                LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER
                DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO
                YOU.
              </p>
              <p>
                Indemnity. You agree to indemnify and hold the FinOptSys Parties
                harmless from and against any and all claims, liabilities,
                damages (actual and consequential), losses and expenses
                (including attorneys’ fees) arising from or in any way related
                to any claims relating to (a) your use of the Services, and (b)
                your violation of these Terms. In the event of such a claim,
                suit, or action (“Claim”), we will attempt to provide notice of
                the Claim to the contact information we have for you (provided
                that failure to deliver such notice shall not eliminate or
                reduce your indemnification obligations hereunder).
              </p>
              <p>
                Assignment. You may not assign, delegate or transfer these Terms
                or your rights or obligations hereunder, or your Services
                account, in any way (by operation of law or otherwise) without
                FinOptSys’ prior written consent. We may transfer, assign, or
                delegate these Terms and our rights and obligations without
                consent.
              </p>
              <p>
                Choice of Law. These Terms are governed by and will be construed
                under the Federal Arbitration Act, applicable federal law, and
                the laws of the State of New Jersey, without regard to the
                conflicts of laws provisions thereof.
              </p>
              <p id="disputesArbitration">
                Arbitration Agreement. Please read the following ARBITRATION
                AGREEMENT carefully because it requires you to arbitrate certain
                disputes and claims with FinOptSys and limits the manner in
                which you can seek relief from FinOptSys. Both you and FinOptSys
                acknowledge and agree that for the purposes of any dispute
                arising out of or relating to the subject matter of these Terms,
                FinOptSys’ officers, directors, employees and independent
                contractors (“Personnel”) are third-party beneficiaries of these
                Terms, and that upon your acceptance of these Terms, Personnel
                will have the right (and will be deemed to have accepted the
                right) to enforce these Terms against you as the third-party
                beneficiary hereof.
              </p>
              <p>
                (a) Arbitration Rules; Applicability of Arbitration Agreement.
                The parties shall use their best efforts to settle any dispute,
                claim, question, or disagreement arising out of or relating to
                the subject matter of these Terms directly through good-faith
                negotiations, which shall be a precondition to either party
                initiating arbitration. If such negotiations do not resolve the
                dispute, it shall be finally settled by binding arbitration in
                Mercer County, New Jersey. The arbitration will proceed in the
                English language, in accordance with the JAMS Streamlined
                Arbitration Rules and Procedures (the “Rules”) then in effect,
                by one commercial arbitrator with substantial experience in
                resolving intellectual property and commercial contract
                disputes. The arbitrator shall be selected from the appropriate
                list of JAMS arbitrators in accordance with such Rules. Judgment
                upon the award rendered by such arbitrator may be entered in any
                court of competent jurisdiction.
              </p>
              <p>
                (b) Costs of Arbitration. The Rules will govern payment of all
                arbitration fees. FinOptSys will pay all arbitration fees for
                claims less than seventy-five thousand ($75,000) dollars.
                FinOptSys will not seek its attorneys’ fees and costs in
                arbitration unless the arbitrator determines that your claim is
                frivolous.
              </p>
              <p>
                (c) Small Claims Court; Infringement. Either you or FinOptSys
                may assert claims, if they qualify, in small claims court in
                Mercer County, New Jersey or any United States county where you
                live or work. Furthermore, notwithstanding the foregoing
                obligation to arbitrate disputes, each party shall have the
                right to pursue injunctive or other equitable relief at any
                time, from any court of competent jurisdiction, to prevent the
                actual or threatened infringement, misappropriation or violation
                of a party’s copyrights, trademarks, trade secrets, patents or
                other intellectual property rights.
              </p>
              <p>
                (d) Waiver of Jury Trial. YOU AND FINOPTSYS WAIVE ANY
                CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A
                TRIAL IN FRONT OF A JUDGE OR JURY. You and FinOptSys are instead
                choosing to have claims and disputes resolved by arbitration.
                Arbitration procedures are typically more limited, more
                efficient, and less costly than rules applicable in court and
                are subject to very limited review by a court. In any litigation
                between you and FinOptSys over whether to vacate or enforce an
                arbitration award, YOU AND FINOPTSYS WAIVE ALL RIGHTS TO A JURY
                TRIAL, and elect instead to have the dispute be resolved by a
                judge.
              </p>
              <p>
                (e) Waiver of Class or Consolidated Actions. ALL CLAIMS AND
                DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
                ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A
                CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
                ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF
                ANY OTHER CUSTOMER OR USER. If however, this waiver of class or
                consolidated actions is deemed invalid or unenforceable, neither
                you nor FinOptSys is entitled to arbitration; instead all claims
                and disputes will be resolved in a court as set forth in (g)
                below.
              </p>
              <p>
                (f) Opt-out. You have the right to opt out of the provisions of
                this Section by sending notice of your decision to opt out to
                the following email address:
                <span className="font-weight-bold px-1">
                  info@finoptsys.com
                </span>
                within thirty (30) days of first accepting these Terms. You must
                include (i) your name and residence address, (ii) the email
                address and/or telephone number associated with your account,
                and (iii) a clear statement that you want to opt out of these
                Terms’ arbitration agreement.
              </p>
              <p>
                (g) Exclusive Venue. If you send the opt-out notice in (f),
                and/or in any circumstances where the foregoing arbitration
                agreement permits either you or FinOptSys to litigate any
                dispute arising out of or relating to the subject matter of
                these Terms in court, then the foregoing arbitration agreement
                will not apply to either party, and both you and FinOptSys agree
                that any judicial proceeding (other than small claims actions)
                will be brought in the state or federal courts located in,
                respectively, Mercer County, New Jersey, or the federal district
                in which that county falls.
              </p>
              <p>
                h) Severability. If the prohibition against class actions and
                other claims brought on behalf of third parties contained above
                is found to be unenforceable, then all of the preceding language
                in this Arbitration Agreement section will be null and void.
                This arbitration agreement will survive the termination of your
                relationship with FinOptSys.
              </p>
              <p>
                Miscellaneous. You will be responsible for paying, withholding,
                filing, and reporting all taxes, duties, and other governmental
                assessments associated with your activity in connection with the
                Services, provided that the FinOptSys may, in its sole
                discretion, do any of the foregoing on your behalf or for itself
                as it sees fit. The failure of either you or us to exercise, in
                any way, any right herein shall not be deemed a waiver of any
                further rights hereunder. If any provision of these Terms are
                found to be unenforceable or invalid, that provision will be
                limited or eliminated, to the minimum extent necessary, so that
                these Terms shall otherwise remain in full force and effect and
                enforceable. You and FinOptSys agree that these Terms are the
                complete and exclusive statement of the mutual understanding
                between you and FinOptSys, and that these Terms supersede and
                cancel all previous written and oral agreements, communications
                and other understandings relating to the subject matter of these
                Terms. You hereby acknowledge and agree that you are not an
                employee, agent, partner, or joint venture of FinOptSys, and you
                do not have any authority of any kind to bind FinOptSys in any
                respect whatsoever.
              </p>
              <p>
                Except as expressly set forth in the section above regarding the
                arbitration agreement, you and FinOptSys agree there are no
                third-party beneficiaries intended under these Terms.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default TermsAndConditions;
