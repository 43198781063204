import React, {useEffect} from "react";
import clientCentric from "../images/svgImages/client_centric.svg";
import connectedData from "../images/svgImages/connected_data.svg";
import p2pExecution from "../images/svgImages/p2p_finance.svg";
import quantitativeIntelligence from "../images/svgImages/quantitative_int.svg";
import stateOfArt from "../images/svgImages/state_of_art.svg";
// import ValueProposition from "./ValueProposition";

const BusinessConcept = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <main role="main" className="d-flex flex-column">
            <section className="business-concept dark-bg" id="business-concept">
                <div className="container px-lg-5">
                    <h3 className="text-center business-heading">Business Concepts</h3>

                    <div className="row align-items-center">
                        <div className="col-24 mb-5">
                            <div className="card">
                                <div className="row no-gutters">
                                    <div className="col-md-8 col-lg-5">
                                        <div className="card-img-wrap">
                                            <img
                                            src={clientCentric}
                                            className="img-fluid card-img card-img-left"
                                            alt="Client-Centric"/>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card-body">
                                            <h2 className="featurette-heading pt-0 text-center-sm">Client-Centric</h2>
                                            <p className="card-text lead text-justify">
                                                Our approach to business is simple: Put the client at the heart of everything we
                                                do. We make it our priority to provide an exceptional experience for our clients
                                                by maximizing our service and product offerings while we continue to deepen our
                                                relationship.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-24 col-lg-8 mb-5">
                            <div className="card">
                                <div className="row no-gutters">
                                    <div className="col-md-8 col-lg-24">
                                        <div className="card-img-wrap">
                                        <img
                                            src={p2pExecution}
                                            className="img-fluid card-img card-img-top"
                                            alt="Peer to Peer Financing (P2P)"/></div></div>
                                    <div className="col">
                                        <div className="card-body">
                                            <h2 className="featurette-heading  text-center-sm">Peer to Peer Financing (P2P)</h2>
                                            <p className="card-text lead text-justify">
                                                Peer to Peer Financing (P2P) is our global state of the art cloud based
                                                proprietary SecFin module, developed specifically for the buy-side community. It
                                                delivers access to expanded sources of liquidity, diversification of
                                                counterparties, lowering of costs, increased transparency while providing a
                                                central connection point to foster greater collaboration and information sharing
                                                amongst the user group.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-24 col-lg-8 mb-5">
                            <div className="card">
                                <div className="row no-gutters">
                                    <div className="col-md-8 col-lg-24">
                                        <div className="card-img-wrap">
                                        <img
                                            src={quantitativeIntelligence}
                                            className="img-fluid card-img card-img-top"
                                            alt="Quantitative Intelligence"/></div></div>
                                    <div className="col">
                                        <div className="card-body">
                                            <h2 className="featurette-heading  text-center-sm">Quantitative Intelligence</h2>
                                            <p className="card-text lead text-justify">
                                                Drives our proprietary multi-dimensional LP &amp; HP mathematical modelling
                                                algorithms and provides clients with quantifiable and actionable decision making
                                                tools, to efficiently construct portfolios and initiate trade actions for
                                                seemless P2P and STP execution with a fully patented DLT offering.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-24 col-lg-8 mb-5">
                            <div className="card">
                                <div className="row no-gutters">
                                    <div className="col-md-8 col-lg-24">
                                        <div className="card-img-wrap">
                                        <img
                                            src={connectedData}
                                            className="img-fluid card-img card-img-top"
                                            alt="Connected and Data Science"/></div></div>
                                    <div className="col">
                                        <div className="card-body">
                                            <h2 className="featurette-heading  text-center-sm">Connected and Data Science</h2>
                                            <p className="card-text lead text-justify">
                                                Inter- connectivity modular approach with real time data feedback loop, ensuring
                                                intelligent and efficient self-correction between actual and optimal decision
                                                making. Overlay of data science, modern statistical tools, ML and NLP, will
                                                enable clients to extract invaluable information, providing unique insights and
                                                predictive tools.
                                            </p>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-24 mb-5">
                            <div className="card">
                                <div className="row no-gutters">
                                    <div className="col-md-8 col-lg-5">
                                        <div className="card-img-wrap">
                                        <img
                                            src={stateOfArt}
                                            className="img-fluid card-img card-img-left"
                                            alt="State of the Art SaaS Platform"/></div>
                                    </div>
                                    <div className="col">
                                        <div className="card-body">
                                            <h2 className="featurette-heading pt-0 text-center-sm">State of the Art SaaS Platform</h2>
                                            <p className="card-text lead text-justify">
                                                A fully secure, next generation, cloud-based platform, with de minimis cost of
                                                technology investment from clients, delivering a unique multi-factor,
                                                multi-dimensional approach to SecFin business through the utilization of
                                                sophisticated linear and hierarchical programming, providing bona fide
                                                “Solutions for the Future”.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default BusinessConcept;
