import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { paths } from "../constants/paths";

const PrivacyDisclaimer = (props) => {
  useEffect(() => {
    // window.scrollTo(0, 40);
    document.getElementById("terms").scrollIntoView();
  });
  return (
    <main role="main" className="d-flex flex-column terms-privacy-main">
      <section className="dark-bg" id="terms">
        <div className="container pt-5 mt-5 mt-md- px-lg-0">
          <div className="terms-privacy-body-header">
            <h1>Privacy Policy</h1>
            <h6 className="mb-24">Effective date: October 2024</h6>
          </div>
          <div className="terms-content row">
            <div className="col-16">
              <p>
                At FinOptSys, we take your privacy seriously. Please read this
                Privacy Policy to learn how we treat your personal information.
                <span className="font-weight-bold">
                  By using or accessing our Services in any manner, you
                  acknowledge that you accept the practices and policies
                  outlined in this Privacy Policy, and you hereby consent that
                  we will collect, use, and share your information as described
                  in this Privacy Policy.
                </span>
              </p>
              <p className="mb-36">
                Remember that your use of FinOptSys’ Services is at all times
                subject to our Terms of Use, which incorporates this Privacy
                Policy. Any terms we use in this Policy without defining them
                have the definitions given to them in the{" "}
                <Link to={paths.Tnc}>Terms of Use</Link>.{" "}
              </p>
              <h5>What this Privacy Policy Covers</h5>
              <p className="mb-36">
                This Privacy Policy covers how we treat Personal Data that we
                gather when you access or use our Services. Subject to separate
                terms and conditions, we offer use of our products to
                institutional clients only without any direct engagement with
                individual customers. “Personal Data” means any information that
                identifies or relates to a particular individual and also
                includes information referred to as “personally identifiable
                information” or “personal information” under applicable data
                privacy laws, rules, or regulations. This Privacy Policy does
                not cover the practices of companies we don’t own or control or
                people we don’t manage.
              </p>
              <h5>Categories of Personal Data We Collect and Disclose</h5>
              <p className="mb-36">
                The chart in this section details the categories of Personal
                Data we may collect through the Services and disclose to third
                parties for a business purpose. Throughout this Privacy Policy,
                we will refer back to the categories of Personal Data listed in
                this chart.
              </p>
            </div>
          </div>
          <table className="table table-bordered table-responsive-sm">
            <thead>
              <tr>
                <th scope="col" className="category-data">
                  Category of <br /> Personal Data
                </th>
                <th scope="col" className="personal-data">
                  Personal Data <br /> We Collect
                </th>
                <th scope="col" className="categories-parties">
                  Categories of Third Parties With Whom We Share <br /> this
                  Personal Data for a Business Purpose:{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="category-data">Personal Identifiers</th>
                <td className="personal-data">
                  <p className="mb-0">• Name</p>
                  <p>• Email address</p>
                  <p>• Phone number</p>
                </td>
                <td className="personal-data">
                  <p>
                    • Hosting and other technology and communications providers;
                    and
                  </p>
                  <p>• Staff augmentation and contract personnel.</p>
                </td>
              </tr>
              <tr>
                <th className="category-data">
                  Professional or Employment-Related Information
                </th>
                <td className="personal-data">• Job title</td>
                <td className="personal-data">
                  <p>
                    • Hosting and other technology and communications providers;
                    and
                  </p>
                  <p>• Staff augmentation and contract personnel.</p>
                </td>
              </tr>
              <tr>
                <th className="category-data">Device/IP Data</th>
                <td className="personal-data">
                  <p>• IP address</p>
                  <p>• Device ID</p>
                  <p>• Domain service</p>
                  <p>• Type of device/operating </p>
                  <p> system/ browser used to access the Services</p>
                </td>
                <td className="personal-data">
                  <p>
                    • Hosting and other technology and communications providers;
                    and
                  </p>
                  <p>• Staff augmentation and contract personnel.</p>
                </td>
              </tr>
              <tr>
                <th className="category-data">Web Analytics</th>
                <td className="personal-data">
                  <p>• Web page and module interactions</p>
                  <p>
                    • Referring webpage/source through which you accessed the
                    Services
                  </p>
                  <p>
                    • Statistics associated with the interaction between device
                    or browser and the Services
                  </p>
                  <p>• Non-identifiable request IDs </p>
                </td>
                <td className="personal-data">
                  <p>
                    • Hosting and other technology and communications providers;
                    and
                  </p>
                  <p>• Staff augmentation and contract personnel.</p>
                </td>
              </tr>
              <tr>
                <th className="category-data">Geolocation Data</th>
                <td className="personal-data">
                  <p>• IP-address-based location information</p>
                  <p>• GPS data</p>
                </td>
                <td className="personal-data">
                  <p>
                    • Hosting and other technology and communications providers;
                    and
                  </p>
                  <p>• Staff augmentation and contract personnel.</p>
                </td>
              </tr>
              <tr>
                <th className="category-data">Sensory Data</th>
                <td className="personal-data">
                  <p>
                    • Recordings of telephone or other web based conversations
                    with FinOptSys
                  </p>
                </td>
                <td className="personal-data">
                  <p>
                    • Hosting and other technology and communications providers;
                    and
                  </p>
                  <p>• Staff augmentation and contract personnel.</p>
                </td>
              </tr>
              <tr>
                <th className="category-data">
                  Other Identifying Information that You Voluntarily Choose to
                  Provide{" "}
                </th>
                <td className="personal-data">
                  <p>
                    • Identifying information in emails, phone calls,
                    screenshares, instant messages, or letters you send us
                  </p>
                </td>
                <td className="personal-data">
                  <p>
                    • Hosting and other technology and communications providers;
                    and
                  </p>
                  <p>• Staff augmentation and contract personnel.</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="terms-content row">
            <div className="col-16">
              <h5>Sources of Personal Data</h5>
              <p className="mb-0">
                We collect Personal Data about you from the following categories
                of sources:
              </p>
              <ul className="pl-0 list-unstyled privacy-list mb-36">
                <li>
                  You: When you provide such information directly to us, and
                  when you use the Services and such information is collected
                  automatically (e.g. through Cookies (as defined below)).
                </li>
                <li>
                  Third Parties: When third parties, like service providers,
                  provide us with Personal Data about you. For example, third
                  parties may help us provide you with customer support.
                </li>
              </ul>
              <h5>How We Use Your Personal Data</h5>
              <p>
                We process Personal Data to operate, improve, understand and
                personalize our Services. We use Personal Data for the following
                purposes:
              </p>
              <ul className="pl-0 list-unstyled privacy-list mb-36">
                <li>
                  To meet or fulfill the reason you provided the information to
                  us.
                </li>
                <li>
                  To communicate with you about the Services, including Service
                  announcements, updates or offers.
                </li>
                <li>To provide support and assistance for the Services.</li>
                <li>
                  To create and manage your account or other user profiles.
                </li>
                <li>
                  To personalize website content and communications based on
                  your preferences.
                </li>
                <li>To process orders or other transactions.</li>
                <li>To respond to user inquiries and fulfill user requests.</li>
                <li>
                  To improve and develop the Services, including testing,
                  research, analysis and product development.
                </li>
                <li>
                  To protect against or deter fraudulent, illegal or harmful
                  actions and maintain the safety, security and integrity of our
                  Services.
                </li>
                <li>
                  {" "}
                  To comply with our legal or contractual obligations, resolve
                  disputes, and enforce our Terms of Use.
                </li>
                <li>
                  To respond to law enforcement requests and as required by
                  applicable law, court order, or governmental regulations.
                </li>
                <li>
                  For any other business purpose stated when collecting your
                  Personal Data or as otherwise set forth in applicable data
                  privacy laws, such as the California Consumer Privacy Act (the
                  “CCPA”).
                </li>
              </ul>
              <p>
                We will not collect additional categories of Personal Data or
                use the Personal Data we collected for materially different,
                unrelated, or incompatible purposes without providing you
                notice.
              </p>
              <p className="mb-36">
                As noted in the list above, we may communicate with you if
                you’ve provided us the means to do so. For example, if you’ve
                given us your email address, we may email you about your use of
                the Services. Also, we may receive a confirmation when you open
                an email from us, which helps us improve our Services. If you do
                not want to receive communications from us, please indicate your
                preference by emailing us at info@finoptsys.com.
              </p>
              <h5>How We Share Your Personal Data</h5>
              <p>
                Categories of Third Parties with Whom We Share Personal Data:
                <br></br>
                We disclose your Personal Data as indicated in the chart above
                to the following categories of service providers and other
                parties:
              </p>
              <ul className="pl-0 list-unstyled privacy-list">
                <li className="font-weight-bold">Service Providers:</li>
              </ul>
              <p>
                <span className="pr-2">o</span> These are third parties that
                help us provide our Services, including hosting and other
                technology and communications providers, and staff augmentation
                and contract personnel.
              </p>
              <ul className="pl-0 list-unstyled privacy-list">
                <li className="font-weight-bold">
                  Other parties at your direction:
                </li>
              </ul>
              <div>
                <span className="pr-2">o</span>&nbsp;Third-party business
                partners who you access through the Services.
              </div>
              <div className="mb-36">
                <span className="pr-2">o</span>
                &nbsp;Other parties authorized by you.
              </div>
              <p>
                Disclosures of Personal Data for a Business Purpose:<br></br> We
                disclose your Personal Data to service providers and other
                parties for the following business purposes:
              </p>
              <ul className="pl-0 list-unstyled privacy-list">
                <li>
                  Auditing related to a current interaction and concurrent
                  transactions, including, but not limited to, auditing
                  compliance with this specification and other standards.
                </li>
                <li>
                  Detecting security incidents, protecting against malicious,
                  deceptive, fraudulent, or illegal activity, and prosecuting
                  those responsible for that activity.
                </li>
                <li>
                  Debugging to identify and repair errors that impair existing
                  intended functionality.
                </li>
                <li>
                  {" "}
                  Short-term, transient use of Personal Data that is not used by
                  another party to build a consumer profile or otherwise alter
                  your consumer experience outside the current interaction.
                </li>
                <li>
                  Performing services on our behalf, including maintaining or
                  servicing accounts, providing customer service, processing or
                  fulfilling orders and transactions, verifying customer
                  information, processing payments, providing analytic services,
                  or providing similar services on behalf of the business or
                  service provider.
                </li>
                <li>
                  Undertaking internal research for technological development
                  and demonstration.
                </li>
                <li>
                  Undertaking activities to verify or maintain the quality or
                  safety of a service or device that we own, manufacture (or was
                  manufactured for us) or control.
                </li>
              </ul>
              <h5>Tracking Tools, Advertising and Opt-Out</h5>
              <p>
                The Services use cookies and similar technologies such as pixel
                tags, web beacons, clear GIFs and JavaScript (collectively,
                “Cookies”) to enable our servers to recognize your web browser,
                tell us how and when you visit and use our Services, analyze
                trends, learn about our user base and operate and improve our
                Services. Cookies are small pieces of data– usually text files –
                placed on your computer, tablet, phone or similar device when
                you use that device to access our Services. We may also
                supplement the information we collect from you with information
                received from third parties, including third parties that have
                placed their own Cookies on your device(s). Please note that
                because of our use of Cookies, the Services do not support “Do
                Not Track” requests sent from a browser at this time.
              </p>
              <p className="mb-0">We use the following types of Cookies: </p>
              <ul className="pl-0 list-unstyled privacy-list mb-24">
                <li>
                  <span className="font-weight-bold pr-2">
                    Essential Cookies.
                  </span>
                  Essential Cookies are required for providing you with features
                  or services that you have requested. For example, certain
                  Cookies enable you to log into secure areas of our Services.
                  Disabling these Cookies may make certain features and services
                  unavailable.
                </li>
                <li>
                  <span className="font-weight-bold pr-2">
                    {" "}
                    Functional Cookies.
                  </span>
                  Functional Cookies are used to record your choices and
                  settings regarding our Services, maintain your preferences
                  over time and recognize you when you return to our Services.
                  These Cookies help us to personalize our content for you,
                  greet you by name and remember your preferences (for example,
                  your choice of language or region).
                </li>
                <li>
                  <span className="font-weight-bold pr-2">
                    Performance/Analytical Cookies.
                  </span>
                  Performance/Analytical Cookies allow us to understand how
                  visitors use our Services. They do this by collecting
                  information about the number of visitors to the Services, what
                  pages visitors view on our Services and how long visitors are
                  viewing pages on the Services. Performance/Analytical Cookies
                  also help us measure the performance of our advertising
                  campaigns in order to help us improve our campaigns and the
                  Services’ content for those who engage with our advertising.
                </li>
              </ul>
              <p>
                You can decide whether or not to accept Cookies through your
                internet browser’s settings. Most browsers have an option for
                turning off the Cookie feature, which will prevent your browser
                from accepting new Cookies, as well as (depending on the
                sophistication of your browser software) allow you to decide on
                acceptance of each new Cookie in a variety of ways. You can also
                delete all Cookies that are already on your device. If you do
                this, however, you may have to manually adjust some preferences
                every time you visit our website and some of the Services and
                functionalities may not work.
              </p>
              <p className="mb-36">
                To explore what Cookie settings are available to you, look in
                the “preferences” or “options” section of your browser’s menu.
                To find out more information about Cookies, including
                information about how to manage and delete Cookies, please visit
                &nbsp;
                <a
                  href="https://www.allaboutcookies.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.allaboutcookies.org
                </a>
              </p>
              <h5>Data Security and Retention</h5>
              <p>
                We seek to protect your Personal Data from unauthorized access,
                use and disclosure using appropriate physical, technical,
                organizational and administrative security measures based on the
                type of Personal Data and how we are processing that data. For
                example, the Services use industry standard Secure Sockets Layer
                (SSL) technology to allow for the encryption of Personal Data
                you provide to us. You should also help protect your data by, as
                applicable, appropriately selecting and protecting your password
                and/or other sign-on mechanism; limiting access to your computer
                or device and browser; and signing off after you have finished
                accessing your account.{" "}
              </p>
              <p>
                We retain Personal Data about you for as long as you have a
                contractual relationship with us, for as long as needed to
                fulfill a particular purpose for which it was collected and as
                directed by you, or as otherwise necessary to provide you with
                our Services. In some cases we retain Personal Data for longer,
                if doing so is necessary to comply with our legal obligations,
                resolve disputes or collect fees owed, or is otherwise permitted
                or required by applicable law, rule or regulation. We may
                further retain information in an anonymous or aggregated form
                where that information would not identify you personally.
              </p>
              <h5>Personal Data of Children</h5>
              <p className="mb-36">
                We do not knowingly collect or solicit Personal Data from
                children under 16; if you are a child under 16, please do not
                attempt to register for or otherwise use the Services or send us
                any Personal Data. If we learn we have collected Personal Data
                from a child under 16, we will delete that information as
                quickly as possible. If you believe that a child under 16 may
                have provided us Personal Data, please contact us at
                info@finoptsys.com.
              </p>
              <h5>Other State Law Privacy Rights</h5>
              <span>California Resident Rights</span>
              <p>
                Under California Civil Code Sections 1798.83-1798.84, California
                residents are entitled to contact us to prevent disclosure of
                Personal Data to third parties for such third parties’ direct
                marketing purposes; in order to submit such a request, please
                contact us at info@finoptsys.com. Your browser may offer you a
                “Do Not Track” option, which allows you to signal to operators
                of websites and web applications and services that you do not
                wish such operators to track certain of your online activities
                over time and across different websites. Our Services do not
                support Do Not Track requests at this time. To find out more
                about “Do Not Track,” you can visit&nbsp;
                <a
                  href="https://www.allaboutdnt.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.allaboutdnt.com
                </a>
              </p>
              <span>Nevada Resident Rights</span>
              <p>
                If you are a resident of Nevada, you have the right to opt-out
                of the sale of certain Personal Data to third parties who intend
                to license or sell that Personal Data. You can exercise this
                right by contacting us at info@finoptsys.com with the subject
                line “Nevada Do Not Sell Request” and providing us with your
                name and the email address associated with your account. Please
                note that we do not currently sell your Personal Data as sales
                are defined in Nevada Revised Statutes Chapter 603A.{" "}
              </p>
              <h5>Changes to this Privacy Policy</h5>
              <p className="mb-36">
                We’re constantly trying to improve our Services, so we may need
                to change this Privacy Policy from time to time as well, but we
                will alert you to changes by placing a notice on the&nbsp;
                <a href="https://www.finoptsys.com/" rel="noopener noreferrer">
                  www.finoptsys.com
                </a>{" "}
                website, by sending you an email, and/or by some other means.
                Please note that if you’ve opted not to receive legal notice
                emails from us (or you haven’t provided us with your email
                address), those legal notices will still govern your use of the
                Services, and you are still responsible for reading and
                understanding them. If you use the Services after any changes to
                the Privacy Policy have been posted, that means you agree to all
                of the changes. Use of information we collect is subject to the
                Privacy Policy in effect at the time such information is
                collected.
              </p>
              <h5>Contact Information:</h5>
              <p>
                If you have any questions or comments about this Privacy Policy,
                the ways in which we collect and use your Personal Data, your
                choices and rights regarding such use, please do not hesitate to
                contact us at:
              </p>
              <ul className="pl-0 list-unstyled privacy-list">
                <li>
                  Website:{" "}
                  <a
                    href="https://www.finoptsys.com/"
                    rel="noopener noreferrer"
                  >
                    www.finoptsys.com
                  </a>
                </li>
                <li>Email: info@finoptsys.com</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PrivacyDisclaimer;
