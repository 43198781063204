import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../constants/paths";
import fosLogo from "../images/FOS_Logo_withBG.png";

const Footer = (props) => {
  return (
    <section className="section-footer py-4">
      <footer className="container">
        <div className="d-flex flex-md-row flex-column">
          <p className="navbar-brand d-flex footer-brand-name">
            <div className="d-flex align-items-start align-items-xl-center justify-content-xl-center">
              <img
                src={fosLogo}
                className="img-fluid brain-logo"
                alt="Brain Logo"
              />
            </div>
            <span className="f-30">&reg;</span>
          </p>
          <p className="ml-md-auto d-flex align-items-center footer-links flex-sm-column-only">
            <Link to={paths.aboutUs}>About Us</Link>
            <Link to={paths.ourLeadership}>Our Leadership</Link>
            <Link to={paths.whyFinoptsys}>Why FinOptSys</Link>
            <Link to={paths.businessConcept}>Business</Link>
            <Link to={paths.contactUs}>Contact Us</Link>
            <Link to={paths.privacyPolicy}>Privacy Policy</Link>
            <Link to={paths.Tnc}>Terms of Use</Link>
          </p>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
