import React,{ useEffect } from "react";
import ReactDOM from "react-dom";
import {  BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import { paths } from './constants/paths'
import "./index.css";
import App from "./App";
import PrivacyDisclaimer from './components/PrivacyDisclaimer'
import TermsAndConditions from './components/TermsAndConditions'
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import AboutUs from "./components/AboutUs";
import BusinessConcept from "./components/BusinessConcept";
import ContactUs from "./components/ContactUs";
import OurLeadership from "./components/OurLeadership";
import Header from "./components/Header";
import Footer from "./components/Footer";
import WhyFinoptsys from "./components/WhyFinoptsys";
import ScrollToTop from "./components/ScrollToTop";


const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const Main = () =>{

  useEffect(() => {
    const googleAnalyticsId=process.env.REACT_APP_GOOGLE_ANALYTICS_KEY
    ReactGA.initialize(googleAnalyticsId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
	if (path) {
		history.replace(path);
  }
  return (<div onContextMenu={(e)=> e.preventDefault()}>
  <React.StrictMode>
     <Router history={history}>
       <ScrollToTop>
       <Header history={history} />
        <Switch>
          <Route path={paths.aboutUs} component={AboutUs} />
          <Route path={paths.ourLeadership} component={OurLeadership} />
          <Route path={paths.businessConcept} component={BusinessConcept} />
          <Route path={paths.whyFinoptsys} component={WhyFinoptsys} />
          <Route path={paths.contactUs} component={ContactUs} />
          <Route path={paths.privacyPolicy} component={PrivacyDisclaimer} />
          <Route path={paths.Tnc} component={TermsAndConditions} />
				  <Route path={paths.home} component={App} />
				</Switch>
        <Footer history={history} />
       </ScrollToTop>
     </Router>
  </React.StrictMode>
  </div>
  )
}

ReactDOM.render(
  <Main />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
