import React, { useEffect } from "react";
import "./styles/site.scss";
import MainSection from "./components/MainSection";

function App(props) {

    useEffect(() => {
        const tableElements = document.querySelectorAll(".scrollto");
        tableElements.forEach(function(scrollTo) {
            scrollTo
                .addEventListener("click", function(e) {
                    const targetElement = e.target;
                    e.preventDefault();
                    const href = targetElement["hash"];
                    const hrefElement = document.querySelector(href);
                    window.scroll({
                        top: hrefElement && hrefElement.offsetTop - 50,
                        left: 0,
                        behavior: "smooth"
                    });
                });
        });
    });

    return (

        // <main role="main" className="d-flex flex-column">
        <MainSection/>
        // </main>
    );
}

export default App;