import React, { useState, useEffect } from "react";

const portfolio = [
  {
    category: ["all", "executive", "board"],
    name: "Divyesh Bhakta",
    modalName: "Divyesh Bhakta",
    imageName: "divyesh-bhakta",
    position: "Founder & CEO",
    description:
      "<ul><li>Divyesh is Founder & CEO of FinOptSys. " +
      "Divyesh has over 23 years of capital market experience.<" +
      "/li><li>Divyesh started his career with JP Morgan in London, working to build ou" +
      "t their Delta 1 TRS trading efforts. He was recruited by Barclays in 2004 to set" +
      " up their Delta 1 trading business in New York. Following the financial crisis o" +
      "f 2008, Divyesh anticipated that the market would need financial resource tradin" +
      "g and management solutions which included, but not limited to collateral / balan" +
      "ce sheet / regulatory optimization. As such Divyesh moved to Structured Financin" +
      "g & Solutions sales, where he successfully built a leading cross-asset solutions" +
      " team covering global institutional clients.</li><li>Most recently Divyesh was C" +
      "o-Head of Cross Asset Financing distribution at Bank of Montreal where his focus" +
      " was on deepening and broadening their client base, including pension plans, ins" +
      "urance companies and global asset managers.</li><li>Divyesh earned a Bachelor’s " +
      "degree in Mathematics from King’s College, London and is also a Chartered Accoun" +
      "tant and a Fellow of the Institute of Chartered Accountants England and Wales.</" +
      "li></ul>",
  },
  {
    category: ["all", "executive", "board"],
    name: "John Stracquadanio",
    modalName: "John Stracquadanio",
    imageName: "john-stracquadanio",
    position: "Executive Director – Board of Directors",
    description:
      "<ul><li>John is a partner of Appia Ventures, LLC, a private investment firm. Joh" +
      "n’s career spans over 30 years in capital markets, in various senior management " +
      "roles.</li><li>Prior to joining FinOptSys as an Executive Director and Board Mem" +
      "ber, John had recently retired from his role as Head of US Capital Markets and G" +
      "lobal Head of Prime Services & Collateral Management Funding at Scotiabank, havi" +
      "ng joined them in 2010. John also sat on the Global Diversity & Inclusion Steeri" +
      "ng Committee for Scotiabank’s Global Banking & Markets division.</li><li>Prior t" +
      "o that, John was with Barclays Capital, where he held senior roles within Prime " +
      "Services, including Head of U.S. Prime Brokerage and Global Head of Electronic E" +
      "quity Execution. John was with Morgan Stanley and ING, within their respective E" +
      "quity Finance groups before moving to Barclays Capital in 1998.</li><li>John rec" +
      "eived his undergraduate degree in Computer Science and Statistics from Baruch Co" +
      "llege in NY, and his Master’s Degree in Finance from Purdue University Global.</" +
      "li></ul>",
  },
  {
    category: ["all", "executive"],
    name: "Oliver Esslinger",
    modalName: "Oliver Esslinger",
    imageName: "oliver-esslinger",
    position: "Founding Member, Treasurer & Company Secretary",
    description:
      "<ul><li>Oliver also functions as Chief of Staff and Chief Compliance Officer wit" +
      "h over 25 years of professional experience in the financial industry.  </li><li>" +
      "Prior to joining FinOptSys, Oliver worked in several COO related roles within As" +
      "set Management and Banking in CIO/Investment Management, Capital Markets Product" +
      " Structuring and Trading, as well as strategic Business Development roles, all f" +
      "or Deutsche Bank, in New York, London and Frankfurt.  </li><li>Oliver was an int" +
      "egral part of and provided oversight to Deutsche Bank’s corresponding Operations" +
      " and Risk Committees as well as several Governance and Surveillance Councils.  <" +
      "/li><li>Oliver earned a Diploma in Economics from Ruhr-University in Bochum/Germ" +
      "any </li></ul>",
  },
  {
    category: ["all", "executive"],
    name: "Aagmik Parikh",
    modalName: "Aagmik Parikh, CFA",
    imageName: "aagmik-parikh",
    position: "Founding Member & Chief Product Officer",
    description:
      "<ul><li>Aagmik has over 15 years of professional experience in software developm" +
      "ent, product development and platform management, most of them in the financial " +
      "industry.  </li><li>Prior to joining FinOptSys, he worked in several sell-side f" +
      "irms including DeutscheBank, Wells Fargo and Natixis.  </li><li>His background i" +
      "ncludes development of Collateral Management platforms for Funding and Financing" +
      " with focus on Balance Sheet efficiency and Liquidity Management for Prime Broke" +
      "rage desks. </li><li>Aagmik earned his Master of Engineering from Stevens Instit" +
      "ute of Technology in New Jersey and is also a CFA® Charter holder </li></ul>",
  },
  {
    category: ["all", "advisory"],
    name: "Diane Brokenshire",
    modalName: "Diane Brokenshire",
    imageName: "diane-brokenshire",
    position: "",
    description:
      "<ul><li>Diane has over 25 years’ experience in Senior HR leadership roles, spanning various sectors as well as " +
      "multiple geographies and regions. She brings an uncompromising balance between providing strategic " +
      "thought and initiatives underpinning the success of corporate targets, while acutely understanding culture, " +
      "people and climate needed to carry through on achieving those goals." +
      "</li><li>Diane is currently the Global Head HR, Client Businesses and Regional HR Head for Europe & Americas " +
      "at Standard Chartered Bank and is based in Singapore. She is responsible for providing strategic HR " +
      "capability to the client businesses while enhancing the overall performance, potential and profitability with " +
      "the bank in the short, medium and long term. Prior to joining Standard Chartered, Diane spent 10 years " +
      "at Scotiabank in Canada, her last role being Global Head of HR for Wholesale banking. Diane has also " +
      "held Senior HR roles at Nortel Networks based in both EMEA and North America as well as Vale Mining " +
      "in Latin America and North America." +
      "</li><li>Diane holds a BA in Psychology from McMaster University, a HR degree from Humber College and is " +
      "recognized as a Certified HR Professional. Diane has volunteered throughout her career; championing " +
      "causes such as Eva’s Initiative, a charity helping homeless children get off the streets, where she was a " +
      "member of the board for several years.</li></ul>",
  },
  {
    category: ["all", "advisory"],
    name: "Art Certosimo",
    modalName: "Art Certosimo",
    imageName: "art-certosimo",
    position: "",
    description:
      "<ul><li>Art is non executive Chairman of LTX, A Broadridge Company and a senior " +
      "advisor to theexecutive  team  at  Broadridge  Financial  Solutions,  a  leading" +
      "  global  financial  technology firm. As part of his role with Broadridge, he re" +
      "presents the firm on the LiquidX Board of Directors.</li><li>Art is also an acti" +
      "ve member of the Board of Directors for South Street Securities, a FINRA member " +
      " broker/dealer.  In  addition,  from  2002  to  2019,  he was  Vice  Chairman  a" +
      "nd  a member  of  the  Board  of  Advisors  of  Promontory  Interfinancial Netwo" +
      "rk,  the  leading provider of FDIC-insured deposit placement services in the USA" +
      ". </li><li>Art retired from Bank of New York Mellon in December 2014 as a member" +
      " of the Executive Committee and Operating Committee of the Company and as Senior" +
      " Executive Vice President and CEO of Global Markets, leading the company’s forei" +
      "gn exchange, capital markets and derivatives trading businesses worldwide. Prior" +
      " to this role, Art served as Chief Executive Officer of Alternative, Broker-Deal" +
      "er and the Treasury Clearance Groups, where he played a major role in the 2001 9" +
      "11 recovery and the 2008 credit crisis. Before joining The Bank of New York in 1" +
      "998, Art was a principal of Morgan Stanley Asset Management (MSAM). Prior to thi" +
      "s, he was a vice president at The Chase Manhattan Bank’s Global Investor Service" +
      "s group.</li><li>During his 36-year career in International Banking and Capital " +
      "Markets, Art served on a number of boards and committees dedicated to delivering" +
      " actionable solutions to issues facing the financial services industry. Among th" +
      "em: he assisted the Federal Reserve Bank of  New  York  in  designing  and  impl" +
      "ementing  critical  liquidity  facilities  to  support  the banking system, he w" +
      "as the lead director of the Depository Trust & Clearing Corporation (DTCC),  a  " +
      "director  of  the  International  Derivatives  Clearing  Group  (IDCG),  a  memb" +
      "er  of the  Treasury  Market  Practices  Group  (TMPG),  the  Tri-party  Repurch" +
      "ase  agreement Infrastructure  Task  Force  formed  by  the  Federal  Reserve  P" +
      "ayments  System  Risk  (PSR)Committee,  The  Industry  Working  Group on  Paymen" +
      "ts  System  Risk  Policy  Change,  and The NewBank Working Group.</li><li>Art  i" +
      "s  Ex-Chairman  and  member  of  the  Board  of  Overseers  of  the  Rutgers  Un" +
      "iversity Foundation and Chairman of the Board of Advisors of the Rutgers Busines" +
      "s School; where he is also a co-adjutant professor in the Executive MBA program." +
      "</li><li>Art holds a B.A. in Business Administration from Rutgers College at Rut" +
      "gers University and an M.B.A from Fairleigh Dickinson University.</li></ul> ",
  },
  {
    category: ["all", "advisory"],
    name: "Prof. Gordon Clark",
    modalName: "Prof. Gordon Clark",
    imageName: "gordon-clark",
    position: "",
    description:
      "<ul><li>Gordon  L  Clark PhD  DSc  FBA  is  Professorial  Fellow  at  St  Edmund" +
      "  Hall,  Oxford  and  co-director  of  the  Oxford-Zurich  research  program.  W" +
      "ith  expertise  in organization theory, financial decision making, and pensions " +
      "he has held appointments at the John F. Kennedy School  of  Government  at  Harv" +
      "ard  University,  Harvard  Law  School,  the  University  of Chicago, Carnegie M" +
      "ellon University, and Monash University (Australia). He has also been a Mellon F" +
      "ellow at the National Research Council of the US National Academy of Sciences.</" +
      "li><li>Gordon is focused upon decision-making in large financial institutions, t" +
      "aking an organizational perspective focused upon technology and informed by know" +
      "ledge of banks, pension funds, sovereign wealth funds, and endowments. This rese" +
      "arch is to be found in his recent article in Institutional Investors in Global M" +
      "arkets (with Ashby Monk). He has also been a consultant to number of pension fun" +
      "ds and sovereign wealth funds on these issues. </li><li>Gordon is an expert on h" +
      "ow individuals respond to risk and uncertainty and seeks to better understand ho" +
      "w and why people deal with financial risks to their long-term welfare. This rese" +
      "arch is to be found in reports and papers through the Oxford-Zurich research pro" +
      "gram and in Saving for Retirement (with Kendra Strauss and Janelle Knox-Hayes). " +
      "Recent papers on gambling behaviour, financial literacy, and advice-seeking in p" +
      "ension plans have been published in major economics and finance journals. </li><" +
      "li>Gordon has investments in a number of companies in Silicon Valley and elsewhe" +
      "re that combine innovation with impact. He is also the Independent Chair of the " +
      "IP Group’s Ethics and ESG committee and is an Advisor to a handful of start-ups." +
      " </li></ul>",
  },
  {
    category: ["all", "advisory"],
    name: "Kevin Felix",
    modalName: "Kevin Felix",
    imageName: "kevin-felix",
    position: "",
    description:
      "<ul><li>Kevin is a partner of Appia Ventures, LLC, a private investment firm. He" +
      " currently serves as a member of the board of directors of Global Atlantic Finan" +
      "cial Group, a leading reinsurance firm founded by Goldman Sachs, and of Digital " +
      "Global Systems, a technology firm specializing in drone threat management. Kevin" +
      " also is chairman of the board of Spirit Yachts Ltd., the global leader of custo" +
      "m classic wooden sailboats, and is a member of the dean’s advisory council of Vi" +
      "llanova School of Business.  </li><li>Kevin’s career spans 25 years in investmen" +
      "t banking, asset management and corporate law. He retired from Scotiabank in 201" +
      "9 as President and Chief Executive Officer of Scotia Capital (USA) Inc. At Scoti" +
      "a, he managed the Bank’s multibillion dollar capital markets business in the Ame" +
      "ricas, Europe and Asia, and served as Senior Adviser for Global Strategy for ban" +
      "king and markets. Previously, he was a Managing Partner of the private investmen" +
      "t firm Guggenheim Partners, focused on alternative assets and principal investme" +
      "nts. Prior to joining Guggenheim, Kevin was a Managing Director of RBC Capital M" +
      "arkets and was associated with the international law firm of Fried Frank in New " +
      "York. </li><li>Kevin holds a Master of Law degree in Securities Regulation from " +
      "Georgetown University Law Center, a Juris Doctor degree from New York Law School" +
      " and a Bachelor of Arts degree from Bucknell University. </li></ul>",
  },
];

function OurLeadership() {
  const [filter, setFilter] = useState("all");
  const [projects, setProfile] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [activeTeamMember, setActiveTeamMember] = useState("");

  const isActive = activeTab ? activeTab : filter;

  useEffect(() => {
    setProfile(portfolio);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProfile([]);

    const filtered = portfolio.map((p) => ({
      ...p,
      filtered: p.category.includes(filter),
    }));
    setProfile(filtered);
    // window.scrollTo(0, 0);
  }, [filter]);
  const activeMemberObject = projects.find(
    (member) => member.name === activeTeamMember
  );
  return (
    <main role="main" className="d-flex flex-column">
      <section id="our-team">
        <div className="container">
          <h3 className="text-center contact-heading pb-4 mb-2">
            Our Leadership
          </h3>
          <div className="d-flex flex-md-row flex-column no-gutters justify-content-center">
            <div className="col-lg-24 col-md-14 text-center">
              <span className="fs-18">
                FinOptSys’s team of leading industry experts, bring decades of
                experience in Capital Markets and are the foundation of our
                Client-Centric business model.
              </span>
              <span className="fs-18 pt-2 d-block">
                The Leadership team is responsible for the overall strategic
                direction of the firm and for the development and implementation
                of our firms’ values-based culture, capturing our guiding
                principles of integrity, partnership and high performance.
              </span>
            </div>
          </div>
        </div>
        <div className="container">
          {/* <Team  members={MEMBERS} /> */}
          <div className="portfolio-labels">
            <div className="btn-group">
              <span
                active={filter === "all"}
                onClick={() => {
                  setFilter("all");
                  setActiveTab(filter.all);
                }}
                className={`${isActive === "all" ? "active" : ""}`}
              >
                All
              </span>
              <span
                active={filter === "executive"}
                onClick={() => {
                  setFilter("executive");
                  setActiveTab(filter.executive);
                }}
                className={`${isActive === "executive" ? "active" : ""}`}
              >
                Executive Members
              </span>
              <span
                active={filter === "board"}
                onClick={() => {
                  setFilter("board");
                  setActiveTab(filter.board);
                }}
                className={`${isActive === "board" ? "active" : ""}`}
              >
                Board of Directors
              </span>
              <span
                active={filter === "advisory"}
                onClick={() => {
                  setFilter("advisory");
                  setActiveTab(filter.advisory);
                }}
                className={`${isActive === "advisory" ? "active" : ""}`}
              >
                Advisory Board
              </span>
            </div>
          </div>
          <div
            className={`portfolio-container d-flex justify-content-center ${filter}`}
          >
            {!activeMemberObject
              ? projects.map((item) =>
                  item.filtered === true ? (
                    <>
                      {" "}
                      {
                        <div
                          className="member cursor-pointer"
                          onClick={() => setActiveTeamMember(item.name)}
                          key={item.name}
                        >
                          {" "}
                          <img
                            src={"images/" + item.imageName + ".gif"}
                            alt={item.name}
                          />{" "}
                          <span> {item.name} </span>
                        </div>
                      }{" "}
                    </>
                  ) : (
                    ""
                  )
                )
              : ""}
            {activeMemberObject && (
              <div className="active-member-modal d-flex flex-grow-1">
                <button
                  className="btn btn-close-member-modal"
                  onClick={() => setActiveTeamMember("")}
                >
                  <svg
                    viewBox="0 0 492 492"
                    className="icons"
                    height="0.8rem"
                    fill="#666666"
                  >
                    <path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z"></path>
                  </svg>
                </button>
                <div className="left">
                  <div className="member">
                    <img
                      src={"images/" + activeMemberObject.imageName + ".gif"}
                      alt={activeMemberObject.name}
                    />
                    <span className="font-weight-bold">
                      {activeMemberObject.modalName}
                    </span>
                    <span className="text-center">
                      {activeMemberObject.position}
                    </span>
                  </div>
                </div>
                <div className="right">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: activeMemberObject.description,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </main>
  );
}

export default OurLeadership;
