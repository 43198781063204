import React from "react";
import _ from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import IconCheckCircleFill from "./icons";

export default class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      fields: {
        name: "",
        email: "",
        contactNo: "",
        message: "",
      },
      errors: {
        name: "",
        email: "",
        contactNo: "",
        message: "",
      },
      captcha: false,
      g_recaptcha_response: "",
      isFormSubmitted: false,
      showFormErrorModal: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  getErrorMessage = (name) => {
    let errorMessage = "This field is required";
    switch (name) {
      case "name":
        errorMessage = "Name is required";
        break;
      case "email":
        errorMessage = "Email Id required";
        break;
      case "contactNo":
        errorMessage = "Contact number required";
        break;
      case "message":
        errorMessage = "Message required";
        break;
      default:
        break;
    }
    return errorMessage;
  };

  handleBlurEvent = (e) => {
    const { fields, errors } = this.state;
    const name = e.target.name;
    const isEmailValid =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        fields.email
      );
    if (!fields[name]) {
      const updatedErrors = _.cloneDeep(errors);
      updatedErrors[name] = this.getErrorMessage(name);
      this.setState({ errors: updatedErrors });
    } else {
      const updatedErrors = _.cloneDeep(errors);
      if (name === "email") {
        if (!isEmailValid) {
          updatedErrors["email"] = "Email ID is invalid";
        } else {
          updatedErrors["email"] = "";
        }
      } else {
        updatedErrors[name] = "";
      }
      this.setState({ errors: updatedErrors });
    }
  };

  handleChangeEvent = (e) => {
    const { fields } = this.state;
    const name = e.target.name;
    const value = e.target.value;
    const updatedFields = _.cloneDeep(fields);
    updatedFields[name] = value;
    this.setState({ fields: updatedFields });
  };

  isFormValid = (fields) => {
    const { name, email, message, contactNo } = fields;
    return !name || !email || !message || !contactNo;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { g_recaptcha_response, fields, errors } = this.state;

    //const notificationUrl = process.env.REACT_APP_NOTIFICATION_API_HOSTNAME;
    if (this.isFormValid(fields)) {
      const updatedErrors = _.cloneDeep(errors);
      Object.keys(fields).forEach((item) => {
        updatedErrors[item] = !fields[item] && this.getErrorMessage(item);
      });
      this.setState({ errors: updatedErrors });
    } else {
      axios
        .post(
          `https://8cxwlx9j2i.execute-api.us-east-2.amazonaws.com/SiteFormStage/SiteFormNotification`,
          {
            ...fields,
            "g-recaptcha-resource": g_recaptcha_response,
          }
        )
        .then(() => {
          this.setState({ isFormSubmitted: true });
        })
        .catch(() => {
          this.setState({ showFormErrorModal: true });
        });
    }
  };

  handleCaptcha = (key) => {
    this.setState({
      captcha: true,
      g_recaptcha_response: key,
    });
  };

  resetForm = () => {
    this.setState({
      fields: {
        name: "",
        email: "",
        contactNo: "",
        message: "",
      },
      isFormSubmitted: false,
    });
    window.scrollTo(0, 0);
  };

  handleClose = () => {
    this.setState({ showFormErrorModal: false });
  };
  render() {
    const { errors, isFormSubmitted, showFormErrorModal } = this.state;
    const siteKey = `${process.env.REACT_APP_CAPTCHA_SITE_KEY}`;
    return (
      <main role="main" className="d-flex flex-column">
        <section id="contact-us" className="contact-us dark-bg">
          <div className="container">
            <h3 className="text-center contact-heading pb-4 mb-2">
              Contact Us
              <p className="f-24 font-weight-normal pt-3">Info@finoptsys.com</p>
            </h3>
            <div className="d-flex flex-md-row flex-column no-gutters justify-content-center">
              <div className="col-lg-10 col-md-14">
                <div className="card">
                  {isFormSubmitted ? (
                    <div className="card-body px-5 pb-5">
                      <h4 className="text-primary font-weight-normal">
                        <IconCheckCircleFill className="mr-1" />
                        Request Sent
                      </h4>
                      <span className="text-center">
                        Thank you for your interest in FinOptSys,
                        <br /> we will get back to you shortly
                      </span>
                      <button
                        className="btn btn-primary btn-block ok-btn mt-4"
                        onClick={this.resetForm}
                      >
                        OK
                      </button>
                    </div>
                  ) : (
                    <div className="card-body px-3 px-md-5 pb-5">
                      <form onSubmit={this.handleSubmit} className="w-100">
                        <div className="form-group">
                          <label
                            htmlFor="name"
                            className="d-flex justify-content-between align-items-end"
                          >
                            <span>Name</span>
                            <span className="error-message">
                              {this.state.errors.name
                                ? this.state.errors.name
                                : ""}
                            </span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.name ? "is-invalid" : ""
                            }`}
                            id="name"
                            placeholder="Enter Name"
                            name="name"
                            onBlur={this.handleBlurEvent}
                            onChange={this.handleChangeEvent}
                            value={this.state.fields.name}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="email"
                            className="d-flex justify-content-between align-items-end"
                          >
                            <span>Email</span>
                            <span className="error-message">
                              {this.state.errors.email
                                ? this.state.errors.email
                                : ""}
                            </span>
                          </label>
                          <input
                            type="email"
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            id="email"
                            placeholder="Enter Email"
                            name="email"
                            onBlur={this.handleBlurEvent}
                            onChange={this.handleChangeEvent}
                            value={this.state.fields.email}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="contact"
                            className="d-flex justify-content-between align-items-end"
                          >
                            <span>Contact number</span>
                            <span className="error-message">
                              {this.state.errors.contactNo
                                ? this.state.errors.contactNo
                                : ""}
                            </span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.contactNo ? "is-invalid" : ""
                            }`}
                            id="contact"
                            placeholder="Enter Contact number"
                            name="contactNo"
                            onBlur={this.handleBlurEvent}
                            onChange={this.handleChangeEvent}
                            value={this.state.fields.contactNo}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="message"
                            className="d-flex justify-content-between align-items-end"
                          >
                            <span>Message</span>
                            <span className="error-message">
                              {this.state.errors.message
                                ? this.state.errors.message
                                : ""}
                            </span>
                          </label>
                          <textarea
                            className={`form-control ${
                              errors.message ? "is-invalid" : ""
                            }`}
                            id="message"
                            rows="3"
                            placeholder="Enter Message"
                            name="message"
                            onBlur={this.handleBlurEvent}
                            onChange={this.handleChangeEvent}
                            value={this.state.fields.message}
                          ></textarea>
                        </div>
                        <ReCAPTCHA
                          onChange={this.handleCaptcha}
                          ref={this.recaptchaRef}
                          //This ref can be used to call captcha related functions in case you need.
                          sitekey={siteKey}
                          theme="dark"
                          className="captcha-wrapper"
                        />
                        <button
                          type="submit"
                          className="btn btn-primary btn-block submit-btn mt-4 w-80 mx-auto"
                        >
                          Send
                        </button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {showFormErrorModal && (
            <Modal
              show={showFormErrorModal}
              onHide={this.handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title className="font-weight-600 text-secondary">
                  ERROR
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="font-weight-500 text-secondary">
                An error occurred while submitting the form. Please try again,
                after some time.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </section>
      </main>
    );
  }
}
