import React, {useRef} from "react";
import myVideo from "../images/Finoptsys_preview.mp4";
import {Player, ControlBar, BigPlayButton} from "video-react";
import posterFrame from "../images/poster_image.jpg";
import {Analyze, Disrupt, Innovate, Transform} from '../images/svgImages/svgIcons'
import {Link} from "react-router-dom";
import {paths} from "../constants/paths";

const AboutUs = (props) => {

    const videoRef = useRef(null);
    return (
        <main role="main" className="d-flex flex-column">
            <section className="dark-bg" id="about-us">
                <div className="container">
                    <h3 className="text-center about-heading">FinOptSys – 
                        <span className="font-italic"> Solutions for the Future</span></h3>
                    <div
                        className="d-flex flex-md-row flex-column no-gutters justify-content-center">
                        <div className="col-lg-20 col-md-14 text-center">
                            <span className="fs-18">
                                A fully customizable and completely modular enterprise wide cloud-based SaaS platform, deploying patent-pending quantitative intelligent algorithms for Securities Financing, Collateral Optimization and Financial Resource Optimization
                                </span>
                            <br/>
                        </div>
                    </div>
                    <div className="video-wrapper" id="video">
                        <Player ref={videoRef} autoPlay={true} src={myVideo} poster={posterFrame}>
                            <BigPlayButton position="center"/>
                            <ControlBar autoHide={false} className="my-class"/>
                        </Player>
                    </div>
                    <div className="d-flex justify-content-center m-b-60">
                        <Link to={paths.contactUs} className="btn btn-primary btn-xl scrollto submit-btn px-4 btn-schedule-demo">
                            Schedule Demo
                        </Link>
                        {/* <a
                            className="btn btn-primary btn-xl scrollto submit-btn px-4 btn-schedule-demo"
                            href={"#contact-us"}
                            rel="noopener noreferrer">
                            Schedule Demo
                        </a> */}
                    </div>
                    <div className="card finoptsys-inc text-center">
                        <p className="sass-platform">
                            FinOptSys – a State of the Art SaaS Platform
                        </p>
                        <p className="borne-clients">
                            Borne from clients demanding efficient, transparent, analytical and customized
                            cross asset utilization solutions, for financial resources deployed in the
                            global Securities Financing (SecFin) Business.
                        </p>
                    </div>
                    <div className="row mt-20">
                        <div className="col-md-12 col-lg-6 d-flex pb-1 mb-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="image-container">
                                        <Innovate/>
                                    </div>
                                    <p className="abt-head">Innovate</p>
                                    <p>
                                        Innovative, State of the Art SaaS product, offering real-time optimization of
                                        SecFin business using a multi-dimensional approach, enhancing yield, lowering
                                        costs in a transparent manner.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 d-flex pb-1 mb-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="image-container">
                                        <Disrupt/>
                                    </div>
                                    <p className="abt-head">Disrupt</p>
                                    <p>
                                        Disruptor to the global SecFin business by challenging the status quo through
                                        the utilization of quantitative intelligence.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 d-flex pb-1 mb-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="image-container">
                                        <Analyze/>
                                    </div>
                                    <p className="abt-head">Analyze</p>
                                    <p>
                                        First Peer to Peer (P2P) SecFin Platform, uniquely driven by proprietary LP & HP
                                        algorithms and advanced quantitative modelling.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 d-flex pb-1 mb-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="image-container">
                                        <Transform/>
                                    </div>
                                    <p className="abt-head">Transform</p>
                                    <p>
                                        Transforming the SecFin business through intelligent, patent-pending analytics
                                        and actionable decision making, outside the realms of traditional bank offerings
                                        and services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default AboutUs;
