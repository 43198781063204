import React, {useEffect} from "react";
import valuePropositionWeb from "../images/svgImages/value_proposition_web.svg";
import valuePropositionMobile from "../images/svgImages/value_proposition_mobile.svg";

const WhyFinoptsys = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <main role="main" className="d-flex flex-column">
            <section className="value-proposition" id="value-proposition">
                <div className="container">
                    <h3 className="text-center value-heading">Why FinOptSys?</h3>
                    <p className="mb-4 mb-md-0">
                        FinOptSys is the only firm to deliver patent-pending analytics and a fully
                        customizable, modular based approach platform to our clients. It is the first
                        true Peer to Peer Securities Financing Platform and with its unique central
                        connection point, it fosters greater collaboration and information sharing
                        amongst the user group.
                    </p>
                    <div className="value-proposition-img">
                        <img
                            src={valuePropositionWeb}
                            className="img-fluid d-none d-md-block"
                            alt="Why FinOptSys Web"/>
                        <img
                            src={valuePropositionMobile}
                            className="img-fluid d-md-none"
                            alt="Why FinOptSys Mobile"/>
                    </div>
                    <p className="mt-4 mt-md-0 leading-industry-experts">
                        State of the Art SaaS Platform + Leading Industry Experts
                        <br/>
                        <h5 className="mb-0">
                            <i>equals</i>
                        </h5>
                        “Solutions for the Future”, delivered today!
                    </p>
                </div>
            </section>
        </main>
    );
};

export default WhyFinoptsys;
